import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';

/* IMPORT COMPONENT */
import CardFeature from '../CardFeature';

/* IMPORT STYLE */
import './Features.less';

/* IMPORT CONTEXT */
import { lngContext } from '../lngContext';

const Features = ({ showP }) => {
  const { value, setValue } = useContext(lngContext);

  const letterIcon = (
    <StaticImage
      src="../../images/letter-icon.png"
      width={50}
      quality={95}
      formats={['AUTO', 'WEBP', 'AVIF']}
      alt="Letter icon"
    />
  );
  const bookIcon = (
    <StaticImage
      src="../../images/book-icon.png"
      width={50}
      quality={95}
      formats={['AUTO', 'WEBP', 'AVIF']}
      alt="Book icon"
    />
  );
  const headphoneIcon = (
    <StaticImage
      src="../../images/headphones-icon.png"
      width={50}
      quality={95}
      formats={['AUTO', 'WEBP', 'AVIF']}
      alt="Headphones icon"
    />
  );

  const letterList = [
    value === 'fr'
      ? 'Une fabuleuse collection de mosaïques tunisiennes (plus de 160 mosaïques)'
      : 'A fabulous collection of Tunisian mosaics (over 160 mosaics)',
    value === 'fr'
      ? 'De nouvelles mosaïques récemment découvertes et jamais publiées'
      : 'new mosaics recently discovered and never published',
    value === 'fr'
      ? 'Des illustrations des principaux sites archéologiques de la Tunisie'
      : 'Illustrations of the main archaeological sites of Tunisia',
  ];
  const bookList = [
    value === 'fr' ? 'Un livre électronique de plus de 88 pages' : 'An eBook of over 88 pages',
    value === 'fr' ? 'Un glossaire expliquant les termes et les lieux' : 'A glossary explaining terms and locations',
    value === 'fr' ? 'Un repère historique des dates' : 'a historical marker of dates',
  ];
  const headphoneList = [
    value === 'fr' ? 'Plus de 6 heures de narration' : 'Over 6 hours of narration',
    value === 'fr' ? 'Disponible en Français et en Anglais' : 'Available in French and in English',
  ];
  return (
    <div className="wrapper-features" id="feature">
      <div className="container">
        <p className="title-features">{value === 'fr' ? 'Des fonctionnalités illimitées' : 'Unlimited features'} </p>
        <div
          className={`${showP ? 'show-content-features content-features' : 'hide-content-features content-features'}`}
        >
          <CardFeature
            icon={letterIcon}
            title={value === 'fr' ? "Univers d'image avec haute résolution" : 'Image universe with high resolution'}
            list={letterList}
            color="orange"
          />
          <CardFeature icon={bookIcon} title="Livre riche en infromation historique" list={bookList} color="gris" />
          <CardFeature
            icon={headphoneIcon}
            title={value === 'fr' ? 'Auto audio pour une lecture confortable' : 'Auto audio for comfortable playback'}
            list={headphoneList}
            color="griii"
          />
        </div>
      </div>

      <div className="img-left-feature">
        <StaticImage
          src="../../images/img-left-feature.png"
          width={300}
          quality={95}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Feature left icon"
        />
      </div>
    </div>
  );
};

export default Features;
