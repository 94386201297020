import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';

/* IMPORT COMPONENT */
import './CardDownload.less';
import { lngContext } from '../lngContext';

const CardDownload = ({ img, icon, txt, android, ios }) => {
  const { value, setValue } = useContext(lngContext);

  return (
    <div className="wrapper-card-download">
      <div className="img-download">{img}</div>
      <div className="right-bloc">
        <div className="icon-download">{icon}</div>
        <div className="text-download">{txt}</div>
        <div className="btn-download">
          {ios && (
            <a href="#" target="_blank" rel="noreferrer">
              <StaticImage
                src="../../images/ios-icon.png"
                width={15}
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="iOS icon"
              />
              {value === 'fr' ? 'Télécharger lite ' : 'Download lite'}
            </a>
          )}
          {android && (
            <a href="https://play.google.com/store/apps/details?id=com.mosaicsoftunisia.lite" target="_blank" rel="noreferrer">
              <StaticImage
                src="../../images/android-icon.png"
                width={15}
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Android icon"
              />
              {value === 'fr' ? 'Télécharger lite' : 'Download lite'}
            </a>
          )}
        </div>

        <div className="btn-download">
          {ios && (
            <a href="https://apps.apple.com/us/app/mosaics-of-tunisia/id1587920557" target="_blank" rel="noreferrer">
              <StaticImage
                src="../../images/ios-icon.png"
                width={15}
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="iOS icon"
              />
              {value === 'fr' ? 'Télécharger Premium' : 'Download Premium'}
            </a>
          )}
          {android && (
            <a href="https://play.google.com/store/apps/details?id=com.mosaicsoftunisia" target="_blank" rel="noreferrer">
              <StaticImage
                src="../../images/android-icon.png"
                width={15}
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Android icon"
              />
              {value === 'fr' ? 'Télécharger Premium' : 'Download Premium'}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardDownload;
