import React, { useContext } from 'react';

/* IMPORT COMPONENT */
import NavBar from '../NavBar';

/* IMPORT STYLE */
import './Header.less';

/* IMPORT CONTEXT */
import { lngContext } from '../lngContext';

/* IMPORT IMAGES */
import { StaticImage } from 'gatsby-plugin-image';

const Header = ({ show }) => {
  const { value, setValue } = useContext(lngContext);

  return (
    <div className="wrapper-header" id="accueil">
      <NavBar show={show} />
      <div className="custom-img-header">
        <StaticImage
          src="../../images/img-left-header.png"
          width={300}
          quality={95}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="logo Mosaïque antique Tunisie"
        />
      </div>
      <div className="container">
        <div className="content-header">
          <div className="row h-100">
            <div className="d-lg-none d-md-block col-md-1"></div>
            <div className="col-lg-8 col-md-8 col-sm-10 d-flex pr-0 custom-col-left">
              <div className="text">
                <div className="title">
                  <p>
                    {value === 'fr'
                      ? "La Mosaïque : Joyau de l'Art Antique En Tunisie"
                      : 'The Tunisian Mosaics: A Journey Through History Via Ancient Art'}
                  </p>
                </div>
                <div className="description">
                  <p>
                    {value === 'fr'
                      ? `Offrez-vous une prodigieuse collection de mosaïques, toutes découvertes en Tunisie, dans le bassin
                    méditerranéen, témoignant de son immense richesse et la grande diversité de son patrimoine
                    archéologique et historique. De Carthage à Kerkouane, découvrez ce fabuleux héritage qui ornait les
                    édifices publics et privés dans les hauts lieux de la civilisation punique.`
                      : `The massive prodigious collection of the Tunisian mosaics, all discovered in the Mediterranean Basin, offer a look into the history of the region, its immense wealth, and the great. Diversity of its archaeological and architectural monuments. Get the opportunity to discover this amazing heritage of the Punic Civilization, from Carthage to Kerkouane.`}
                  </p>
                </div>
                <div className="buttons">
                  <div className="android">
                    <span>
                      <StaticImage
                        src="../../images/android-icon.png"
                        width={20}
                        quality={95}
                        formats={['AUTO', 'WEBP', 'AVIF']}
                        alt="Android icon"
                      />
                      {value === 'fr' ? 'APPLICATION ANDROID' : 'ANDROID APPLICATION'}
                    </span>
                  </div>
                  <div className="ios">
                    <span>
                      <StaticImage
                        src="../../images/ios-icon.png"
                        width={20}
                        quality={95}
                        formats={['AUTO', 'WEBP', 'AVIF']}
                        alt="iOS icon"
                      />
                      {value === 'fr' ? 'APPLICATION iOS' : 'iOS APPLICATION'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-3 col-sm-12 d-flex justify-content-end align-items-end pl-0 custom-col-right">
              <div className="img">
                <StaticImage
                  src="../../images/img-header.png"
                  width={600}
                  quality={95}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Header icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
