import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';

/* IMPORT COMPONENT */
import CardDownload from '../CardDownload';

/* IMPORT STYLE */
import './Download.less';

/* IMPORT CONTEXT */
import { lngContext } from '../lngContext';

const Download = ({ showP }) => {
  const { value, setValue } = useContext(lngContext);

  const imgAndroid = (
    <StaticImage
      src="../../images/img-download-android.png"
      width={180}
      quality={95}
      formats={['AUTO', 'WEBP', 'AVIF']}
      alt="Android icon"
    />
  );
  const imgIos = (
    <StaticImage
      src="../../images/img-download-ios.png"
      width={200}
      quality={95}
      formats={['AUTO', 'WEBP', 'AVIF']}
      alt="iOS icon"
    />
  );

  const icon = (
    <StaticImage
      src="../../images/icon-download.png"
      width={50}
      quality={95}
      formats={['AUTO', 'WEBP', 'AVIF']}
      alt="Download icon"
    />
  );

  const textAndroid = (
    <p>
      {value === 'fr' ? (
        <span>
          Télécharger <br /> l’application Android
        </span>
      ) : (
        <span>
          Download <br /> the Android app
        </span>
      )}
    </p>
  );
  const textIos = (
    <p>
      {value === 'fr' ? (
        <span>
          Télécharger <br /> l’application iOS
        </span>
      ) : (
        <span>
          Download <br /> the ios app
        </span>
      )}
    </p>
  );

  return (
    <div className="wrapper-download" id="download">
      <div className={`${showP ? 'show-download container' : 'hide-download container'}`}>
        <p className="title-download">
        {value === 'fr' ? (
        <span>
          Téléchargement
        </span>
      ) : (
        <span>
          Download
        </span>
      )}
          </p>
        <div className="wrapper-cards-download">
          <CardDownload img={imgAndroid} icon={icon} txt={textAndroid} android />
          <CardDownload img={imgIos} icon={icon} txt={textIos} ios />
        </div>
      </div>
      <div className="img-right-download">
        <StaticImage
          src="../../images/img-right-download.png"
          width={300}
          quality={95}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Download"
        />
      </div>
    </div>
  );
};

export default Download;
