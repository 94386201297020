import React from 'react';

/* IMPORT STYLE */
import './CardHistory.less';

const CardHistory = ({ txt, color }) => {
  return (
    <div className={`wrapper-card card-${color}`} >
      <p>{txt}</p>
    </div>
  );
};

export default CardHistory;
