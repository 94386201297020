import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

/* IMPORT COMPONENT */
import CardHistory from '../CardHistory';

/* IMPORT STYLE */
import './History.less';

/* IMPORT CONTEXT */
import { lngContext } from '../lngContext';

const History = ({ showP }) => {
  const { value, setValue } = useContext(lngContext);

  return (
    <div className="wrapper-history" id="about">
      <div className="container">
        <div className="content-history">
          <div className="first-bloc">
            <div className="cards">
              <CardHistory
                txt={
                  value === 'fr'
                    ? "Illustration de l'abondance et la prospérité à travers la mosaique"
                    : 'Illustration of abundance and prosperity through the mosaic'
                }
                color="blue"
              />
              <CardHistory
                txt={
                  value === 'fr'
                    ? 'Naissance et développement de la naissance en Tunisie'
                    : 'Birth and birth development in Tunisia'
                }
                color="orange"
              />
              <CardHistory
                txt={value === 'fr' ? 'Loisir, sport, spectacles et cultures' : 'Leisure, sport, shows and cultures'}
                color="rose"
              />
              <CardHistory
                txt={value === 'fr' ? 'Mythes païens et thémes chrétiens' : 'Pagan myths and Christian themes'}
                color="violet"
              />
            </div>
            <div className="img-history">
              <StaticImage
                src="../../images/img-history.png"
                width={300}
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="History image Mosaïque antique Tunisie"
              />
            </div>
          </div>
          <div className="text">
            <div className="title">
              {value === 'fr' ? 'Plongez au cœur de l’histoire ' : 'Step Into the Heart of History'}
            </div>
            <div className="description">
              <p>
                {value === 'fr'
                  ? `Le présent livre électronique évoque par le texte et l’image le somptueux éclat de multiples dizaines d’authentiques chefs d’œuvres dont la splendeur avait éclipsé la production de l’Italie et de la métropole de l’empire romain et dont le rayonnement s’étendit à l’ensemble du monde méditerranéen et même au-delà. 
Outre son incontournable valeur artistique, la fabuleuse collection décrite, constitue une mine de renseignements inépuisables sur la vie quotidienne à l’époque romaine révélant d’une manière étonnamment concrète les activités, les aspirations et les préoccupations des élites sociales et des masses populaires. Elle reflète surtout l’attachement des uns et des autres aux croyances religieuses propres au paganisme puis caractéristiques du christianisme triomphant à partir du IVéme siècle avec son riche répertoire de motifs symboliques et décoratifs puisés dans la bible ou figuratifs empruntés aux écritures saintes.`
                  : `This electronic book evokes the brilliance of dozens of authentic historical masterpieces through texts and pictures,
                   as well as shows Italy and the Metropolis of the Roman Empire’s extended influence to the entire Mediterranean world and even beyond. In addition to the indescribable artistic value, this fabulous collection constitutes a rich source of information as it illustrates the activities of daily life during the Roman era, as well as it sheds light on the aspirations and concerns of the social elites and the popular masses at that time. The collection mirrors the strong attachment to religious beliefs specific to paganism and demonstrates the rise of Christianity since the 4th Century through a set of symbolic and decorative motifs extracted from the Bible and other holy scriptures. `}
              </p>
              <p>
                <strong>{value === 'fr' ? 'Un monde de mythologie grecque' : 'A Complete World of Greek Methodology'} </strong>
              </p>
              <p>
                {value === 'fr'
                  ? `Du regard magnétique de « Méduse », au couronnement de « Dionysos », l’enlèvement de « Ganymède » ou encore le triomphe de « Neptune », revivez en image et son la mythologie et ses histoires trépidantes. 
La collection de mosaïques vous emportera dans un monde illustrant les dieux, déesses, culte de Bacchus et divers autres épisodes de la mythologie où figurent les Muses, les saisons, le génie de l’armée, les calendriers et le temps.`
                  : `The collection of mosaics will take you into the world of Greek Methodology and its hectic stories through pictures and sounds,
                   from the Medusa Gaze and the Crowning of Dionysus to the Abduction of Ganymede and the Triumph of Neptune, in addition to several Greek Gods, Goddesses, heroes, and Bacchanalian rituals and rites.`}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-img-history">
        <StaticImage
          src="../../images/img-right-history.png"
          width={300}
          quality={95}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Image right history Mosaïque antique Tunisie"
        />
      </div>
    </div>
  );
};

export default History;
