import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

/* IMPORT STYLE */
import './Footer.less';

/* IMPORT CONTEXT */
import { lngContext } from '../lngContext';

const Footer = () => {
  const { value, setValue } = useContext(lngContext);

  return (
    <div className="wrapper-footer">
      <div className="nav">
        <span onClick={() => scrollTo('#accueil')}>{value === 'fr' ? 'ACCUEIL' : 'HOME'}</span>
        <span onClick={() => scrollTo('#about')}>{value === 'fr' ? 'À PROPOS' : 'ABOUT'}</span>
        <span onClick={() => scrollTo('#feature')}>{value === 'fr' ? 'FONCTIONNALITÉS' : 'FEATURE'}</span>
        <span onClick={() => scrollTo('#download')}>{value === 'fr' ? 'TÉLÉCHARGER' : 'DOWNLOAD'}</span>
      </div>
      <div className="icon-footer">
        <StaticImage
          src="../../images/icon-download.png"
          width={50}
          quality={95}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="A Gatsby astronaut"
          alt="Download icon"
        />
        <span>2020 &copy; MOT</span>
      </div>
    </div>
  );
};

export default Footer;
