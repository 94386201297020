import React, { useLayoutEffect, useRef, useState } from 'react';

/* IMPORT COMPONENT */
import Header from '../components/Header';
import History from '../components/History';
import Features from '../components/Features';
import Download from '../components/Download';
import Footer from '../components/Footer';

/* IMPORT STYLE */
import 'bootstrap/dist/css/bootstrap.css';

/* IMPORT CONTEXT */
import { lngContext } from '../components/lngContext';
import NavBar from '../components/NavBar';
import isBrowser from '../helpers/isBrowser';

const IndexPage = () => {
  const checkIsBrowser = isBrowser();

  const [value, setValue] = useState('fr');

  /* START STATE SHOW */
  const [show, setShow] = useState({
    isHeader: true,
    isHistory: false,
    isFeatures: false,
    isDownload: false,
  });

  /* START CONST REF */
  const isHeaderRef = useRef(null),
    isHistoryRef = useRef(null),
    isFeaturesRef = useRef(null),
    isDownloadRef = useRef(null);

  // /* START COMPONENT WILLMOUNT */
  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    //added to reduce redundancy
    const headerPos = topPos(isHeaderRef.current),
      historyPos = topPos(isHistoryRef.current),
      featurePos = topPos(isFeaturesRef.current),
      downloadPos = topPos(isDownloadRef.current);

    const onScroll = () => {
      const scrollPos = checkIsBrowser && window.scrollY + 300;

      if (headerPos < scrollPos) {
        setShow((state) => ({ ...state, isHeader: true, isHistory: false, isFeatures: false, isDownload: false }));
      }

      if (historyPos < scrollPos) {
        setShow((state) => ({ ...state, isHeader: false, isHistory: true, isFeatures: false, isDownload: false }));
      }

      if (featurePos < scrollPos) {
        setShow((state) => ({ ...state, isHeader: false, isHistory: false, isFeatures: true, isDownload: false }));
      }

      if (downloadPos < scrollPos) {
        setShow((state) => ({ ...state, isHeader: false, isHistory: false, isFeatures: false, isDownload: true}));
      }
    };

    checkIsBrowser && window.addEventListener('scroll', onScroll);
    return () => checkIsBrowser && window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <lngContext.Provider value={{ value, setValue }}>
      <div>
        <div ref={isHeaderRef}>
          <Header show={show} />
        </div>
        <div ref={isHistoryRef}>
          <History showP={show.isHistory} />
        </div>
        <div ref={isFeaturesRef}>
          <Features showP={show.isFeatures} />
        </div>
        <div ref={isDownloadRef}>
          <Download showP={show.isDownload} />
        </div>
        <Footer />
      </div>
    </lngContext.Provider>
  );
};

export default IndexPage;
