import React from 'react';

/* IMPORT STYLE */
import './CardFeature.less';

const CardFeature = ({ icon, title, list, color }) => {
  return (
    <div className={`wrapper-card-feature wrapper-card-feature-${color}`}>
      <span className="icon">{icon}</span>
      <span className="card-feature-title">{title}</span>
      <div className="card-feature-list">
        <ul>{list && list.map((el, i) => <li key={i}>{el}</li>)}</ul>
      </div>
    </div>
  );
};

export default CardFeature;
